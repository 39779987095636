<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <h4>Lesson: {{ GET_ONE_CONTENT.lesson }}</h4></b-col>
    </b-row>
    <custom-dragbar
      v-if="GET_ONE_CONTENT.description"
    >
      <template v-slot:left-side>
        <b-card
          class="h-100 mr-1 overflow-auto"
        >
          <div
            v-html="GET_ONE_CONTENT.description"
          />
        </b-card>
      </template>
      <template v-slot:right-side>
        <b-card class="h-100 ml-1 overflow-auto">
          <b-tabs>
            <b-tab
              v-for="item in GET_ONE_CONTENT.folder_files"
              :key="item.id"
            >
              <template
                v-if="item.data_type.length"
                #title
              >
                <feather-icon :icon="item.data_type[1]" />
                <span>
                  {{ item.data_type[0] }}
                </span>
              </template>
              <template
                v-else-if="item.url"
                #title
              >
                <feather-icon icon="LinkIcon" />
                <span>
                  url
                </span>
              </template>
              <all-doc-viewer :src="item.url? item.url : item.file" />
            </b-tab>
          </b-tabs>
        </b-card>
      </template>
    </custom-dragbar>

    <b-card
      v-else
      class="h-100 overflow-auto"
    >
      <b-tabs>
        <b-tab
          v-for="item in GET_ONE_CONTENT.folder_files"
          :key="item.id"
        >
          <template
            v-if="item.data_type.length"
            #title
          >
            <feather-icon :icon="item.data_type[1]" />
            <span>
              {{ item.data_type[0] }}
            </span>
          </template>
          <template
            v-else-if="item.url"
            #title
          >
            <feather-icon icon="LinkIcon" />
            <span>
              url
            </span>
          </template>
          <all-doc-viewer :src="item.url? item.url : item.file" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BCard, BTab, BTabs, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import customDragbar from '@/components/customDragbar.vue'
import AllDocViewer from '@/components/AllDocViewer.vue'

export default {
  components: {
    customDragbar,
    BCard,
    BTab,
    BTabs,
    AllDocViewer,
    BRow,
    BCol,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('courses', ['GET_LESSON_CONTENT', 'GET_LESSON', 'GET_ONE_CONTENT']),
  },
  created() {
    this.FETCH_LESSON(this.$route.params.themeId)
      .then(() => {
        this.updateBreadcrumb()
      })
    // this.FETCH_ONE_CONTENT(this.$route.params.themeId)
  },
  methods: {
    ...mapActions('courses', ['FETCH_LESSON', 'FETCH_ONE_CONTENT']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),

    updateBreadcrumb() {
      const [first, second, three] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.to = `/course/${this.$route.params.id}`
      second.active = false
      three.text = this.GET_ONE_CONTENT.lesson
      this.UPDATE_BREADCRUMB([first, second, three])
    },
  },
}
</script>

<style>

</style>
