<template>
  <div
    class="w-100  h-100 position-relative"
    style="min-height: 400px;"
  >
    <div
      v-if="loader"
      class="position-absolute bv d-flex justify-content-center align-items-center"
    >
      <div>
        <b-spinner
          style="width: 5rem; height: 5rem;"
          variant="secondry"
        />
        <h4 class="mt-1 text-secondry">
          Loading...
        </h4>
      </div>
    </div>
    <div
      v-if="docType1 || docType2"
    >
      <iframe
        v-if="docType1"
        class="w-100 h-100 position-absolute"
        :src="url"
        frameborder="0"
      />
      <iframe
        v-if="docType2"
        id="frame_doc"
        ref="aaaa"
        class="position-absolute"
        :src="`https://view.officeapps.live.com/op/view.aspx?src=${url}`"
        width="100%"
        height="100%"
        frameborder="0"
      >This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
      <!-- <iframe
        v-if="docType2"
        class="position-absolute"
        :src="`https://docs.google.com/gview?url=${url}&embedded=true`"
        width="100%"
        height="100%"
        frameborder="0"
      /> -->
    </div>
    <div
      v-if="img"
      class="w-100 h-100 position-absolute d-flex justify-content-center align-items-center"
    >
      <b-img
        class="text-center w-100 h-100"
        thumbnail
        fluid
        :src="url"
        alt="Image 1"
      />
    </div>
    <div
      v-else-if="audio"
      class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <audio
        controls
        crossorigin
        playsinline
      >
        <source
          :src="url"
          :type="`audio/${defoult}`"
        >
        Your browser does not support the audio element.
      </audio>
    </div>
    <div
      v-else-if="video"
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <video
        id="my-video"
        class="w-100 h-100"
        controls
        preload="auto"
        width="500"
        height="500"
        data-setup="{}"
      >
        <source
          :src="url"
          :type="`video/${defoult}`"
        >
      </video>
    </div>
    <div
      v-else-if="embed"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="url"
        allowfullscreen
      />
    </div>
    <div
      v-if="noFormat"
      class="w-100 d-flex align-items-center justify-content-center"
    >
      <h3>Такой формат не поддерживается!</h3>
    </div>
  </div>
</template>

<script>
import { BSpinner, BImg, BEmbed } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BImg,
    BEmbed,
  },
  props: {
    src: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loader: false,
      docLoader: false,
      video: false,
      docType1: false,
      docType2: false,
      img: false,
      audio: false,
      noFormat: false,
      embed: false,
      docFormats1: ['pdf'],
      docFormats2: ['pptx', 'xls', 'ppt', 'docx', 'doc'],
      imgFormats: ['bmp', 'jpg', 'jpeg', 'gif', 'png', 'jfif'],
      audioFormats: ['mp3', 'wav', 'ogg'],
      videoFormats: ['webm', 'mp4'],
      defoult: '',
      // url: 'https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx',
      // url: 'https://www.cmu.edu/blackboard/files/evaluate/tests-example.xls',
      // url: 'https://file-examples-com.github.io/uploads/2017/08/file_example_PPT_1MB.ppt',
      // url: 'https://file-examples-com.github.io/uploads/2017/02/file-sample_1MB.docx',
      // url: 'https://file-examples-com.github.io/uploads/2017/02/file-sample_1MB.doc',
      // url: 'https://www.orimi.com/pdf-test.pdf',
      // url: 'https://filesamples.com/samples/document/doc/sample2.doc',

      // img
      // url: 'https://media0.giphy.com/media/gw3IWyGkC0rsazTi/200.gif',
      // url: 'https://images.pexels.com/photos/1525041/pexels-photo-1525041.jpeg',
      // url: 'https://wallpaperaccess.com/full/1940043.jpg',
      // url: 'https://c4.wallpaperflare.com/wallpaper/32/328/965/abstract-galaxies-2000x1600-space-galaxies-hd-art-wallpaper-preview.jpg',
      // url: 'https://wallpaperaccess.com/full/2623849.jpg',
      // url: 'https://wallpaperaccess.com/full/1140537.jpg',

      // url: 'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_5MG.mp3',
      // url: 'https://file-examples-com.github.io/uploads/2017/11/file_example_WAV_1MG.wav',
      // url: 'https://file-examples-com.github.io/uploads/2017/11/file_example_OOG_5MG.ogg',
      // url: 'https://filesamples.com/samples/audio/wma/sample1.wma',

      // url: 'https://filesamples.com/samples/video/mp4/sample_640x360.mp4', // 500kb
      // url: 'https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1920_18MG.mp4', // 17mb
      // url: 'https://download.samplelib.com/mp4/sample-30s.mp4', // 17mb

      // url: 'https://download.samplelib.com/webm/sample-30s.webm', // 51mb
    }
  },
  watch: {
    src(val) {
      this.setNull()
      if (val) {
        this.url = val
        let format = val.split('.')
        format = format[format.length - 1].toLowerCase()
        if (this.url.search('www.youtube.com/embed') !== -1) {
          this.$nextTick(() => {
            this.embed = true
          })
        } else {
          this.$nextTick(() => {
            this.check(format)
          })
        }
      }
    },
  },
  mounted() {
    this.setNull()
    this.loader = true
    if (this.src) {
      this.url = this.src
      let format = this.src.split('.')
      format = format[format.length - 1].toLowerCase()
      if (this.url.search('www.youtube.com/embed') !== -1) {
        this.$nextTick(() => {
          this.embed = true
        })
      } else {
        this.$nextTick(() => {
          this.check(format)
        })
      }
    }
  },
  destroyed() {
    this.setNull()
  },
  methods: {
    check(format) {
      console.log('sdsd')
      if (this.docFormats1.includes(format)) {
        console.log('doc1')
        this.docType1 = true
      } else if (this.docFormats2.includes(format)) {
        console.log('doc2')
        this.docType2 = true
      } else if (this.imgFormats.includes(format)) {
        this.img = true
      } else if (this.audioFormats.includes(format)) {
        this.defoult = format
        this.audio = true
      } else if (this.videoFormats.includes(format)) {
        this.defoult = format
        this.video = true
      } else {
        this.$nextTick(() => {
          this.noFormat = true
        })
      }
      this.$nextTick(() => {
        this.loader = false
      })
    },
    setNull() {
      this.url = null
      this.docType1 = false
      this.docType2 = false
      this.img = false
      this.video = false
      this.audio = false
      this.noFormat = false
      this.embed = false
    },
  },

}
</script>

<style>
.bv {
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/*
      style="overflow: scroll; cursor: grab; cursor : -o-grab; cursor : -moz-grab; cursor : -webkit-grab;" */
</style>
